<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="table_contracts"
      :footer-props="{
        itemsPerPageText: $t('datatable.rows_per_page'),
        itemsPerPageAllText: $t('datatable.all'),
        pageText: '',
      }"
    >
      <template v-slot:item="props">
        <tr>
          <td>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon :color="props.item.statusColor" v-on="on">mdi-record</v-icon>
              </template>
              <span>{{ $t(`contracts.${props.item.status}`) }}</span>
            </v-tooltip>
          </td>
          <td>{{ props.item.channelName }}</td>
          <td class="text-right">{{ $moment(props.item.createdAt).format('DD/MM/YYYY') }}</td>
        </tr>
      </template>

      <template slot="no-data"> <v-icon>mdi-file-document-multiple</v-icon> {{ $t('contracts.empty_list') }} </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Contracts',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      headers: [
        { text: this.$t('common.status'), value: 'status' },
        { text: this.$t('contracts.channel'), value: 'channelName' },
        { text: this.$t('medicalTests.created_at'), value: 'createdAt', align: 'right' },
      ],
    };
  },
};
</script>

<style>
.table_contracts {
  margin-top: 45px !important;
}
</style>
