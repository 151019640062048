<template>
  <div v-if="!showDontHaveService" id="pageList" class="container">
    <v-container>
      <v-row align="center" justify="center">
        <v-card elevation="0" max-width="1100" width="100%" class="transparent mb-5">
          <v-layout wrap>
            <v-flex xs12 class="mb-5 text-right px-5 px-xl-0">
              <v-menu offset-y bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    data-test="service-button-new"
                    depressed
                    color="primary"
                    dark
                    v-on="on"
                  >
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    {{ $t('common.nuevo_servicio') }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item color="primary" @click="onNewServiceClicked(0)">
                    <v-list-item-title>{{ $t('tipovideo.nuevo_video') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item color="primary" @click="onNewServiceClicked(1)">
                    <v-list-item-title>{{ $t('tipovideo.nuevo_presencial') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="stripeConfigured && modulos.suscripcion"
                    color="primary"
                    @click="mostrarDialogSuscripcion = true"
                  >
                    <v-list-item-title>{{ $t('suscripciones.nueva') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-flex>

            <v-flex v-if="showAgeGroupSpecialties" xs12 lg12 md12 class="px-5 px-xl-0">
              <v-toolbar dark color="primary" dense>
                <v-toolbar-title class="white--text"
                ><v-icon>mdi-message-text</v-icon>&nbsp;{{ $t('minors_attention.title') }} </v-toolbar-title
                >
              </v-toolbar>
              <v-card class="mb-4">
                <v-card-text>
                  <v-progress-linear v-if="loadingSpecialties" indeterminate color="primary" />
                  <v-layout wrap>
                    <v-flex xs12>
                      <AgeGroupSpecialties v-if="professionalSpecialties.length > 0" v-model="ageGroupSpecialtiesRelation" :professional-id="professionalId" :professional-specialties="professionalSpecialties" />
                    </v-flex>
                    <v-flex xs12 style="text-align: right; margin-top: -15px" class="px-4">
                      <v-btn
                        v-if="ageGroupSpecialtiesRelation"
                        :loading="savingMinors"
                        depressed
                        color="green"
                        dark
                        @click="saveMinors"
                      >{{ $t('common.save') }}</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>

            <v-flex v-if="isMessagesActive" xs12 lg12 md12 class="px-5 px-xl-0">
              <v-toolbar dark color="primary" dense>
                <v-toolbar-title class="white--text"
                ><v-icon>mdi-message-text</v-icon>&nbsp;{{ $t('menu.messaging') }}</v-toolbar-title
                >
              </v-toolbar>
              <v-card class="mb-4">
                <v-card-text>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-card-text>
                        <v-text-field
                          data-test="service-input-description"
                          v-model="mensajeria.descripcion"
                          data-v-step="0"
                          :outlined="outlined"
                          :label="$t('common.descripcion')"
                          @input="cambiaMensajeria('blur')"
                        />
                      </v-card-text>
                    </v-flex>
                    <v-flex v-if="has_payment_module" xs6>
                      <v-select
                        v-model="mensajeria.taxType"
                        :items="taxTypes"
                        class="px-4"
                        :label="$t('common.taxType')"
                        auto
                        :outlined="outlined"
                        required
                        item-text="name"
                        item-value="id"
                      />
                    </v-flex>
                    <v-flex v-if="has_payment_module" xs6>
                      <v-text-field
                        v-model="mensajeria.taxPercent"
                        :outlined="outlined"
                        :label="$t('common.taxPercent')"
                        type="number"
                        step="0.01"
                        min="0.01"
                        class="px-4"
                        @input="cambiaMensajeria('blur')"
                      />
                    </v-flex>
                    <v-flex v-if="has_payment_module" xs6>
                      <v-text-field
                        v-model="mensajeria.netAmount"
                        data-v-step="1"
                        :outlined="outlined"
                        :label="
                          mensajeria.netAmount > 0
                            ? $t('common.precio')
                            : $t('common.precio') + ' (' + $t('common.gratuita') + ')'
                        "
                        :rules="[rules.min_or_equal]"
                        type="number"
                        step="0.01"
                        pattern="[0-9]*"
                        min="0"
                        class="px-4"
                        append-icon="mdi-currency-eur"
                        @keyup="cambiaMensajeria('writing')"
                        @blur="cambiaMensajeria('blur')"
                      />
                    </v-flex>
                    <v-flex v-if="has_payment_module && mensajeria.netAmount > 0 && mensajeria.taxPercent > 0" xs6>
                      <v-text-field
                        v-model="mensajeria.precio"
                        :outlined="outlined"
                        :label="$t('facturacion.importe')"
                        readonly="readonly"
                        class="px-4"
                      />
                    </v-flex>
                    <v-flex xs6 style="margin-top: -45px" class="container">
                      <v-switch
                        data-test="service-switch-estate"
                        v-model="mensajeria.estado"
                        data-v-step="2"
                        :label="
                          mensajeria.estado === 0 || mensajeria.estado === '0' || mensajeria.estado === false
                            ? $t('common.hide_to_hiring')
                            : $t('common.show_to_hiring')
                        "
                        color="primary"
                        @change="cambiaMensajeria('blur')"
                      />
                    </v-flex>
                    <v-flex xs6 style="text-align: right; margin-top: -15px" class="px-4">
                      <v-btn
                        v-if="mensajeria.modificado"
                        data-v-step="3"
                        depressed
                        color="green"
                        dark
                        @click="guardarMensajeria"
                      >{{ $t('common.save') }}</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>

            <v-flex lg12 md12 class="px-5 px-xl-0">
              <v-card class="mb-4">
                <v-toolbar color="primary" dark flat dense cad>
                  <v-toolbar-title class="white--text"
                  ><v-icon>mdi-video</v-icon>&nbsp;{{ $t('common.videoconferences') }}</v-toolbar-title
                  >
                  <v-spacer />
                </v-toolbar>
                <v-card-text style="margin-top: -15px">
                  <tablavideos
                    :videoconsultas="videoconsultas"
                    :payment-module="has_payment_module"
                    :tipo="0"
                    :tipo-servicio="tipoServicio"
                    :prev-text-fee-message="prevTextFeeMessage"
                    :is-freemium="paymentSettings.isFreemium"
                    :outlined="outlined"
                    :tax-types="taxTypes"
                    :is-receptionist="isReceptionist"
                    @editadoTipoVideo="editadoTipoVideo"
                    @cerrarDialog="closeDialogComponent"
                    @mostrarAlert="showAlertChild"
                  />
                </v-card-text>
              </v-card>
            </v-flex>

            <v-flex v-if="presenciales.length > 0" lg12 md12 class="px-5 px-xl-0">
              <v-card class="mb-4">
                <v-toolbar color="primary" dark flat dense cad>
                  <v-toolbar-title class="white--text"
                  ><v-icon>mdi-map-marker</v-icon>&nbsp;{{ $tc('videoconsulta.presencial', 2) }}</v-toolbar-title
                  >
                  <v-spacer />
                </v-toolbar>
                <v-card-text style="margin-top: -15px">
                  <tablavideos
                    :videoconsultas="presenciales"
                    :payment-module="has_payment_module"
                    :tipo="1"
                    :is-freemium="paymentSettings.isFreemium"
                    :prev-text-fee-message="prevTextFeeMessage"
                    :outlined="outlined"
                    :tax-types="taxTypes"
                    :is-receptionist="isReceptionist"
                    @editadoTipoVideo="editadoTipoVideo"
                    @cerrarDialog="closeDialogComponent"
                    @mostrarAlert="showAlertChild"
                  />
                </v-card-text>
              </v-card>
            </v-flex>

            <v-flex v-if="stripeConfigured && modulos.suscripcion" lg12 md12 class="px-5 px-xl-0">
              <v-card class="mb-4">
                <v-toolbar color="primary" dark flat dense cad>
                  <v-toolbar-title class="white--text"
                  ><v-icon>mdi-star</v-icon>&nbsp;{{ $t('suscripciones.suscripciones') }}</v-toolbar-title
                  >
                  <v-spacer />
                </v-toolbar>
                <v-card-text style="margin-top: -15px">
                  <suscripciones
                    :suscripciones="subscriptions"
                    :pacientes="patients"
                    :servicios="servicios"
                    :payment-module="has_payment_module"
                    :mostrar-dialog="mostrarDialogSuscripcion"
                    :outlined="outlined"
                    :tax-types="taxTypes"
                    @cerrarDialog="closeDialogComponent"
                    @mostrarAlert="showAlertChild"
                  />
                </v-card-text>
              </v-card>
            </v-flex>

            <v-flex v-if="isEnabledMarketplace" lg12 md12 class="px-5 px-xl-0">
              <v-card class="mb-4">
                <v-toolbar color="primary" dark flat dense cad>
                  <v-toolbar-title class="white--text"
                  ><v-icon>mdi-file-document-multiple</v-icon>&nbsp;{{ $t('contracts.contracts') }}</v-toolbar-title
                  >
                  <v-spacer />
                </v-toolbar>
                <v-card-text style="margin-top: -15px">
                  <contracts :items="contracts" />
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-row>
    </v-container>
    <!-- Modal Confirmar  -->
    <span slot="widget-content">
      <v-dialog v-model="dialog_confirm.dialog" dialog_confirm max-width="500px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialog_confirm.name }}</span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <p>{{ dialog_confirm.text }}</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-1" text @click="dialog_confirm.dialog = false"> {{ $t('common.cancel') }}</v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="
                dialog_confirm.dialog = false;
                confirmDialog('confirm');
              "
            >{{ $t('common.confirm') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </span>

    <!-- Modal Nuevo -->
    <span slot="widget-content">
      <v-dialog v-model="dialog_new.dialog" dialog_new max-width="600px" scrollable persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('tipovideo.nuevo_titulo') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex v-if="textFeeMessage !== ''" xs12>
                  <v-alert color="warning" icon="mdi-information" :value="true">
                    {{ prevTextFeeMessage }}
                  </v-alert>
                  <v-alert color="warning" icon="mdi-information" :value="true">
                    {{ textFeeMessage }}
                  </v-alert>
                </v-flex>
                <v-flex v-if="isReceptionist" xs12>
                  <ProfessionalsSelector
                    :return-numeric-id="true"
                    @professionalSelected="professionalSelected"
                  />
                </v-flex>
                <v-flex xs12 sm8>
                  <v-text-field
                    data-test="service-new-input-name"
                    ref="name"
                    v-model="nuevotipo.nombre"
                    :rules="[rules.required]"
                    :outlined="outlined"
                    :label="$t('common.name') + ' *'"
                    type="text"
                  />
                </v-flex>
                <v-flex xs12 sm4>
                  <v-select
                    data-test="service-new-input-type"
                    v-model="nuevotipo.tipoVideo"
                    :items="tipoServicio"
                    style="width: 99%"
                    :label="$t('videoconsulta.tipo')"
                    auto
                    :outlined="outlined"
                    required
                    item-text="name"
                    item-value="id"
                  />
                </v-flex>
                <v-flex xs12 lg12 md12>
                  <v-textarea
                    data-test="service-new-input-description"
                    v-model="nuevotipo.descripcion"
                    :label="$t('common.descripcion')"
                    :outlined="outlined"
                    class="textarea_videoconsulta"
                    rows="2"
                  />
                </v-flex>
                <v-flex v-if="has_payment_module" xs12 lg6 md6>
                  <v-select
                    v-model="nuevotipo.taxType"
                    :items="taxTypes"
                    style="width: 99%"
                    :label="$t('common.taxType')"
                    auto
                    :outlined="outlined"
                    required
                    item-text="name"
                    item-value="id"
                  />
                </v-flex>
                <v-flex v-if="has_payment_module" xs12 lg6 md6>
                  <v-text-field
                    v-model="nuevotipo.taxPercent"
                    :label="$t('common.taxPercent')"
                    type="number"
                    step="0.01"
                    min="0.01"
                    :outlined="outlined"
                    @keyup="cambiaVideoconsulta('writing')"
                    @blur="cambiaVideoconsulta('blur')"
                  />
                </v-flex>
                <v-flex v-if="has_payment_module" xs12 lg6 md6>
                  <v-text-field
                    v-model="nuevotipo.precio"
                    :label="$t('common.precio')"
                    type="number"
                    step="0.01"
                    min="0.01"
                    :outlined="outlined"
                    append-icon="mdi-currency-eur"
                    @keyup="cambiaVideoconsulta('writing')"
                    @blur="cambiaVideoconsulta('blur')"
                  />
                </v-flex>
                <v-flex v-if="has_payment_module && nuevotipo.precio > 0 && nuevotipo.taxPercent > 0" xs12 sm6>
                  <v-text-field
                    v-model="nuevotipo.importe"
                    :rules="[rules.required]"
                    :label="$t('facturacion.importe')"
                    readonly="readonly"
                    :outlined="outlined"
                  />
                </v-flex>
                <v-flex xs12 lg6 md6>
                  <v-text-field
                    data-test="service-new-input-time"
                    ref="duration"
                    v-model="nuevotipo.duracion"
                    :label="$t('common.duracion') + ' *'"
                    :rules="[rules.min]"
                    type="number"
                    min="1"
                    max="1440"
                    suffix="min."
                    :outlined="outlined"
                    append-icon="mdi-clock-outline"
                    @input="cambiaVideoconsulta()"
                    @change="modifyDuration"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn data-test="service-new-button-cancel" color="grey darken-1" text @click.native="dialog_new.dialog = false">{{
                $t('common.cancel')
              }}</v-btn>
            <v-btn data-test="service-new-button-confirm" color="primary" text @click.native="confirmDialog('nuevo')">{{ $t('common.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </span>

    <div class="text-center">
      <v-dialog v-model="dialogSalirSinGuardar" width="500">
        <v-card>
          <v-card-title class="headline lighten-2">{{ $t('common.titulo_salir_sin_guardar') }}</v-card-title>
          <v-divider />
          <v-card-text>{{ $t('common.salir_sin_guardar') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" dark text @click="dialogSalirSinGuardar = false">{{ $t('common.cancel') }}</v-btn>
            <v-btn color="primary" dark text @click="confirmarSalir">{{ $t('common.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-tour name="myTour" :steps="steps" :options="myOptions" />
  </div>
  <div v-else>
    <no-service-granted view="servicios" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import NoServiceGranted from '@/components/common/NoServiceGranted.vue';
import Suscripciones from '@/components/ehealth/ajustes/Suscripciones.vue';
import Tablavideos from '@/components/ehealth/ajustes/Tablavideos.vue';
import { haveEprescriptionActive } from '@/utils';
import {
  tiposVideoconsulta,
  saveSettingServiceUrl,
  saveMessageServiceUrl,
  getHeader,
  listServicesUrl,
  marketplaceUrl, getUsuarioUrl, getProfileUrl,
} from '@/config/config';
import Contracts from '@/components/ehealth/ajustes/Contracts';
import { getProfessionals } from '@/views/professionals/professionals.api';
import ProfessionalsSelector from "@/components/professionals/ProfessionalsSelector.vue";
import AgeGroupSpecialties from "@/MinorsTreatment/AgeGroupSpecialties/sections/AgeGroupSpecialties.vue";
import {setAgeGroupToProfessionalSpecialty} from "@/MinorsTreatment/AgeGroupSpecialties/services";

export default {
  components: {
    AgeGroupSpecialties,
    ProfessionalsSelector,
    Contracts,
    Suscripciones,
    Tablavideos,
    NoServiceGranted,
  },

  data: function () {
    return{
      dialogSalirSinGuardar: false,
      outlined: true,
      pageNext: {},
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      isReceptionist:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3,
      loading: true,
      videoconsultas: [],
      presenciales: [],
      contracts: [],
      patients: [],
      professionals: [],
      mensajeria: {
        estado: 0,
        estado_inicial: 0,
        precio: 0,
        modificado: false,
        descripcion: '',
        taxPercent: 0,
        taxType: 0,
        importe: 0,
      },
      colors: [],
      nuevotipo: {
        id: 0,
        descripcion: '',
        nombre: '',
        duracion: '',
        precio: '',
        tipoVideo: 0,
        taxType: 0,
        taxPercent: 0,
        importe: 0,
        professionalId: 0,
      },
      dialog_confirm: { name: '', text: '', icon: '', tipo: 0, dialog: false },
      dialog_new: { dialog: false },
      rules: {},
      tipoServicio: [
        { id: 0, name: 'Videoconsulta' },
        { id: 1, name: 'Presencial' },
      ],
      subscriptions: [],
      mostrarDialogSuscripcion: false,
      mostrarDialogTablaVideos: false,
      servicios: [],
      stripeConfigured: false,
      has_payment_module: false,
      steps: [
        { target: '[data-v-step="0"]', content: '' },
        { target: '[data-v-step="1"]', content: '' },
        { target: '[data-v-step="2"]', content: '' },
        { target: '[data-v-step="2"]', content: '' },
      ],
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish',
        },
      },
      modulos: {
        mensajeria: true,
        videoconsulta: true,
        suscripcion: true,
      },
      textFeeMessage: '',
      prevTextFeeMessage: '',
      isMessagesActive: true,
      showDontHaveService: false,
      taxTypes: [],
      ageGroupSpecialtiesRelation: null,
      specialties: [],
      allSpecialties: [],
      loadingSpecialties: false,
      savingMinors: false,
    };
  },
  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
      companyPermissions: state => state.account.authUser.company.permissions,
      paymentSettings: state => state.app.paymentSettings,
      showAgeGroupSpecialties() {
        return !this.isReceptionist && this.companyPermissions?.minorsTreatment;
      },
      ageGroupRelationFormatted() {
        if (this.ageGroupSpecialtiesRelation === null) {
          return null;
        }
        return this.ageGroupSpecialtiesRelation.map((relation) => {
          return {
            specialtyId: relation.id,
            ageGroup: relation.ageGroup,
          }
        })
      }
    }),
    ...mapGetters({
      isEnabledMarketplace: 'app/getMarketplaceFeatureFlag',
    }),
    professionalId() {
      return this.authUser?.user?.professional_docline_api_id;
    },
    professionalSpecialties() {
      let professionalSpecialties = [];

      if (this.allSpecialties.length && this.specialties.length) {
        professionalSpecialties = this.allSpecialties.filter((specialty) => {
          return this.specialties.includes(specialty.id);
        })
      }

      return professionalSpecialties;
    },
  },
  created() {
    this.retrieveProfessionalSpecialties();
    if (haveEprescriptionActive()) {
      this.showDontHaveService = true;
    } else {
      this.rules = {
        required: value => !!value || this.$t('common.obligatorio'),
        min: v => v > 0 || this.$tc('errors.mayor_cero', 0),
        min_or_equal: v => v >= 0 || this.$tc('errors.mayor_cero', 2),
      };
      const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
      if (authUser !== null && typeof authUser.servicios_activos !== 'undefined') {
        if (
          typeof authUser.servicios_activos.messagingPermission !== 'undefined' &&
          authUser.servicios_activos.messagingPermission === 0
        ) {
          this.isMessagesActive = false;
        }
        if (typeof authUser.payment_module !== 'undefined') {
          this.has_payment_module = authUser.payment_module;
        }
      }
    }
  },
  async mounted() {
    this.modulos.suscripcion = this.modulos.suscripcion && this.permissions.subscriptionsHired;
    this.isMessagesActive = this.permissions.messaging;
    await this.listServices();
    if (this.isReceptionist) {
      const professionalsResponse = await getProfessionals(1, 200);
      this.professionals = professionalsResponse.items;
    }
    await this.listContracts();
  },

  methods: {
    ...mapActions('layout', ['showAlert']),

    professionalSelected(val) {
      this.nuevotipo.professionalId = val;
    },

    listServices() {
      this.authUser?.id &&
      this.$http
        .get(listServicesUrl, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data) {
            this.videoconsultas = response.data.videoconsultationsServices;
            this.presenciales = response.data.faceToFaceServices;
            this.mensajeria = response.data.messageService;
            this.mensajeria.estado_inicial = response.data.messageService.estado;
            this.servicios.push({ id: 0, name: this.$t('menu.messaging') });

            this.videoconsultas.forEach(videconsulta => {
              videconsulta.estado_inicial = videconsulta.estado;
              videconsulta.modificado = false;
              this.servicios.push({ id: videconsulta.id, name: videconsulta.nombre });
            });
            this.patients = response.data.patients;
            this.patients.unshift({ value: 0, label: this.$t('common.cualquiera') });
            this.subscriptions = response.data.subscriptionsServices;
            this.stripeConfigured = response.data.stripeConfigured;
            this.tipoServicio[0]['name'] = this.$t('videoconsulta.singular');
            this.tipoServicio[1]['name'] = this.$tc('videoconsulta.presencial', 1);
            this.has_payment_module = response.data.paymentModule;

            if (this.paymentSettings.isFreemium) {
              this.showFeeMessage(
                this.paymentSettings['fee_type'],
                this.paymentSettings['fee_value'],
                this.paymentSettings['fee_min'],
                this.paymentSettings['fee_max']
              );
            }
            this.taxTypes = response.data.taxTypes;
          }
        })
        .catch(() => {
          this.$toast.error(this.$t('errors.try_again'));
        });
    },

    async listContracts() {
      if (!this.isEnabledMarketplace) {
        return;
      }
      const professionalId = JSON.parse(window.localStorage.getItem('auth_ehealth'))['user']['professional_id'];
      try {
        const raw = await fetch(`${marketplaceUrl}professionals/${professionalId}/contracts`, {
          headers: getHeader(),
        });
        const body = await raw.json();

        if (typeof body.error !== 'undefined') {
          return;
        }

        this.contracts = body.contracts.map(function(item) {
          return {
            statusColor: item.status === 'accepted' ? 'green' : 'red',
            status: item.status,
            channelName: item.senderPlatform,
            createdAt: item.created_at,
          };
        });
      } catch (e) {
        console.error(e);
      }
    },

    cambiarEstado(tipo) {
      this.videoconsultas[tipo].estado = this.videoconsultas[tipo].stade === 'Yes' ? 2 : 1;
      this.videoconsultas[tipo].modificado = true;
    },

    cambiarEstadoMensajeria() {
      this.mensajeria.estado = this.mensajeria.estado === 1 ? 2 : 1;
      this.mensajeria.modificado = true;
    },

    cambiaVideoconsulta(modo) {
      const precio =
        this.nuevotipo.precio === '' ||
        this.nuevotipo.precio < 0 ||
        (!this.nuevotipo.precio.match(/^-?\d*(\.\d+)?$/) && modo !== 'writing')
          ? '0'
          : this.nuevotipo.precio;

      if (this.paymentSettings.isFreemium && precio <= 0) {
        // precio = '1';
      }

      this.nuevotipo.precio = precio;
      this.nuevotipo.importe =
        Math.round((parseFloat(precio) + (parseFloat(precio) * this.nuevotipo.taxPercent) / 100) * 100) / 100;
      this.nuevotipo.duracion =
        this.nuevotipo.duracion === '' || this.nuevotipo.duracion < 1 ? 1 : this.nuevotipo.duracion;
    },

    modifyDuration() {
      const step = 5;
      this.nuevotipo.duracion = Math.ceil(this.nuevotipo.duracion / step) * step;
    },

    cambiaMensajeria(modo) {
      this.mensajeria.modificado = true;
      let netAmount = parseFloat(this.mensajeria.netAmount);
      if (modo !== 'writing' && (netAmount === '' || netAmount < 0)) {
        netAmount = 0;
      }
      this.mensajeria.netAmount = netAmount;
      this.mensajeria.precio =
        Math.round(
          (parseFloat(netAmount) + (parseFloat(netAmount) * parseFloat(this.mensajeria.taxPercent)) / 100) * 100
        ) / 100;
    },

    onNewServiceClicked(typeService) {
      this.dialog_new.dialog = true;
      this.nuevotipo.descripcion = '';
      this.nuevotipo.nombre = '';
      this.nuevotipo.tipoVideo = typeService;
      this.nuevotipo.duracion = '';
      this.nuevotipo.precio = '';
      this.nuevotipo.professionalId = 0;
    },

    showFeeMessage(feeType, feeValue, feeMin, feeMax) {
      this.prevTextFeeMessage = this.$t('videoconsulta.freemium_version_non_free');
      const FIXED_FEE_TYPE = 0;
      const PERCENTAGE_FEE_TYPE = 1;

      switch (feeType) {
        case FIXED_FEE_TYPE:
          this.textFeeMessage = this.$t('videoconsulta.fixed_fee_message', { feeValue: feeValue });
          break;
        case PERCENTAGE_FEE_TYPE:
          this.textFeeMessage = this.$t('videoconsulta.percentage_fee_message', {
            feeValue: feeValue,
            feeMin: feeMin,
            feeMax: feeMax,
          });
          break;
      }
    },

    postSaveTipo(tipo) {
      if (
        tipo === -1 &&
        (this.nuevotipo.nombre === '' ||
          this.nuevotipo.duracion === '' ||
          (this.isReceptionist && this.nuevotipo.professionalId === 0))
      ) {
        if (this.nuevotipo.nombre === '') {
          this.$refs.name.focus();
        } else if (this.nuevotipo.duracion === '') {
          this.$refs.duration.focus();
        }
        this.showAlert({ color: 'warning', icon: 'mdi-alert-circle', message: this.$t('errors.form_invalid') });
      } else {
        this.dialog_new.dialog = false;
        const postTipo =
          tipo === 'mensajeria' ? this.mensajeria : tipo === -1 ? this.nuevotipo : this.videoconsultas[tipo];
        postTipo.tipo = tipo === 'mensajeria' ? 'mensajeria' : 'videoconsulta';
        const url = tipo === 'mensajeria' ? saveMessageServiceUrl : saveSettingServiceUrl;
        this.$http.post(url, { user_id: this.authUser.id, tipo: postTipo }, { headers: getHeader() }).then(response => {
          if (response.status === 200 && response.data.estado === '1') {
            const msg =
              tipo === 'mensajeria'
                ? this.$t('success.save_tipomensajeria')
                : this.$t('success.save_tipovideo', { name: postTipo.tipo });
            this.showAlert({ color: 'success', icon: 'mdi-check', message: msg });
            this.listServices();
          } else {
            const msg =
              response.status === 200 && parseInt(response.data.estado) === 2
                ? this.$t('videoconsulta.freemium_version_non_free')
                : this.$t('errors.try_again');
            this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: msg });
          }
        });
      }
    },

    postDeleteTipo(tipo) {
      this.$http
        .post(
          tiposVideoconsulta,
          { user_id: this.authUser.id, accion: 'eliminar', tipo_id: this.videoconsultas[tipo].id },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === '1') {
            this.showAlert({ color: 'success', icon: 'mdi-delete', message: this.$t('success.delete_common') });
            this.videoconsultas.splice(tipo, 1);
          } else {
            this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: this.$t('errors.try_again') });
          }
        });
    },

    confirmDialog(tipo_dialog) {
      if (tipo_dialog === 'nuevo') {
        this.postSaveTipo(-1);
      } else {
        if (this.dialog_confirm.icon === 'save') {
          this.postSaveTipo(this.dialog_confirm.tipo);
        } else {
          this.postDeleteTipo(this.dialog_confirm.tipo);
        }
      }
    },

    editadoTipoVideo(data) {
      if (data.tipo === 0) {
        this.videoconsultas.splice(data.indice, 1);
        this.presenciales.push(data.item);
      } else {
        this.presenciales.splice(data.indice, 1);
        this.videoconsultas.push(data.item);
      }
      this.showAlert({ color: 'success', icon: 'mdi-check', message: this.$t('success.save_common') });
    },

    guardarVideonconsulta(tipo) {
      if (
        this.videoconsultas[tipo].estado_inicial !== this.videoconsultas[tipo].estado &&
        this.videoconsultas[tipo].estado === 2
      ) {
        this.dialog_confirm.name = this.$t('tipovideo.guardar');
        this.dialog_confirm.text = this.$t('tipovideo.confirm_desactivar');
        this.dialog_confirm.icon = 'mdi-content-save';
        this.dialog_confirm.dialog = true;
        this.dialog_confirm.tipo = tipo;
      } else {
        this.postSaveTipo(tipo);
      }
    },

    guardarMensajeria() {
      if (this.mensajeria.estado_inicial !== this.mensajeria.estado && this.mensajeria.estado === 2) {
        this.dialog_confirm.name = this.$t('tipovideo.guardarmensajeria');
        this.dialog_confirm.text = this.$t('tipovideo.confirm_desactivar_mensajeria');
        this.dialog_confirm.icon = 'mdi-content-save';
        this.dialog_confirm.dialog = true;
        this.dialog_confirm.tipo = 'mensajeria';
      } else {
        this.postSaveTipo('mensajeria');
      }
    },

    saveMinors() {
      this.savingMinors = true;
      setAgeGroupToProfessionalSpecialty(this.authUser.user.professional_docline_api_id, this.ageGroupRelationFormatted)
        .then(() => {
          this.showAlert({ color: 'success', icon: 'mdi-check', message: this.$t('success.save_common') });
        })
        .catch(() => {
          this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: this.$t('errors.try_again') });
        })
        .finally(() => {
          this.savingMinors = false;
        });
    },

    abrirDialogDelete(tipo) {
      this.dialog_confirm.name = this.$t('tipovideo.confirm_delete_titulo');
      this.dialog_confirm.text = this.$t('tipovideo.confirm_delete_texto');
      this.dialog_confirm.icon = 'mdi-delete';
      this.dialog_confirm.dialog = true;
      this.dialog_confirm.tipo = tipo;
    },

    confirmarSalir() {
      this.dialogSalirSinGuardar = false;
      this.pageNext();
    },

    closeDialogComponent(data) {
      if (data.tipo === 'tablavideos') {
        this.mostrarDialogTablaVideos = !data.estado;
      } else {
        this.mostrarDialogSuscripcion = !data.estado;
      }
    },

    showAlertChild(alert) {
      this.showAlert({ color: alert.tipo, icon: alert.icono, message: alert.texto });
    },

    retrieveProfessionalSpecialties() {
      this.loadingSpecialties = true;
      this.$http
        .post(getProfileUrl, { id: this.authUser.user.id }, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data) {
            this.allSpecialties = response.data.all_especialidades;
            this.specialties = response.data.especialidades;
          }
        })
        .catch(err => {

        }).finally(() => {
        this.loadingSpecialties = false;
      });
    },

    // retrieveProfessionalSpecialties() {
    //   console.log(this.authUser);
    //   this.$http
    //     .post(getUsuarioUrl, { user_id: this.authUser.user.id, usuario_id: this.authUser.user.professional_id }, { headers: getHeader() })
    //     .then(response => {
    //       if (response.status === 200 && response.data) {
    //         this.allSpecialties = response.data.all_especialidades;
    //         this.specialties = response.data.especialidades;
    //       }
    //     })
    //     .catch(err => {
    //
    //     });
    // }
  },
  beforeRouteLeave(to, from, next) {
    let vistaModificada = false;
    if (this.mensajeria.modificado) {
      vistaModificada = true;
    } else {
      for (let i = 0; i < this.videoconsultas.length; i++) {
        if (this.videoconsultas[i].modificado) {
          vistaModificada = true;
        }
      }
    }
    if (vistaModificada && to['name'] !== 'Login') {
      this.dialogSalirSinGuardar = true;
      this.pageNext = next;
      next(false);
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="css">
.boton_save:hover {
  cursor: pointer !important;
}

.btn_float {
  z-index: 5;
  bottom: 60px;
  right: 8px;
}

.d-toolbar {
  position: absolute;
  margin-top: 65px !important;
  top: 20px;
  right: 20px;
}

@media only screen and (max-width: 660px) {
  .d-toolbar {
    right: 0;
  }
}
</style>
